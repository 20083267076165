// (Copyright) Confluent, Inc.
// @flow

import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  getComponentTypes,
  getVerificationLabel,
  getEnterpriseSupport,
  ManagedInCloud,
  PluginLogo,
  type Plugin,
} from '@confluent/connector-plugin-view';
import { sanitizeHtml } from '@confluent/core';

import { shortDescription, getLicenseType, getInstallationInfo } from '../../../models/plugin';

const LOGO_SIZE = 245;

export const Item = ({ plugin, className }: { className?: string, plugin: Plugin, ... }) => (
  <GatsbyLink to={plugin.path}>
    <PluginWrapper className={className}>
      <Title>{plugin.title}</Title>

      <LineBetween />
      <Type data-testid="plugin-type">{`${getComponentTypes(plugin)} connector`}</Type>
      <LineBottom />
      <LogoWrapper>
        <PluginLogo height={LOGO_SIZE} logo={plugin.logo} width={LOGO_SIZE} />
      </LogoWrapper>
      <Content>
        <Description>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(shortDescription(plugin), {
                allowLinks: true,
                allowBTags: true,
              }),
            }}
          />
        </Description>

        {plugin.cloud_availability && (
          <ManagedInCloudWrapper>
            <ManagedInCloud cloudLink={false} />
          </ManagedInCloudWrapper>
        )}

        <Details>
          <FeatureGroup>
            <Feature>
              <Name>Enterprise support:</Name>
              <Value data-testid="enterprise-support">{getEnterpriseSupport(plugin)}</Value>
            </Feature>
            <Feature>
              <Name>Installation:</Name>
              <Value data-testid="installation-info">{getInstallationInfo(plugin)}</Value>
            </Feature>
          </FeatureGroup>
          <FeatureGroup>
            <Feature>
              <Name>Verification:</Name>
              <Value data-testid="verification">{getVerificationLabel(plugin)}</Value>
            </Feature>
            <Feature>
              <Name>Author:</Name>
              <Value data-testid="author">{plugin.owner.name}</Value>
            </Feature>
          </FeatureGroup>
          <FeatureGroup>
            <Feature>
              <Name>License:</Name>
              <Value data-testid="license">{getLicenseType(plugin)}</Value>
            </Feature>
            <Feature>
              <Name>Version:</Name>
              <Value>{plugin.version}</Value>
            </Feature>
          </FeatureGroup>
        </Details>
      </Content>
    </PluginWrapper>
  </GatsbyLink>
);

export default Item;

const GatsbyLink = styled(Link)`
  color: inherit;
  margin-bottom: 32px;
  display: block;
  &:last-child {
    margin-bottom: 0;
  }

  :hover {
    color: inherit;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FeatureGroup = styled.div`
  min-width: 20%;
`;

const Feature = styled.div`
  margin-bottom: 10px;
  &:nth-of-type(2) {
    margin-bottom: 0;
    opacity: 0.32;
  }
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 22px;
`;

const Name = styled(Value)`
  font-weight: 500;
`;

const Description = styled.div`
  font-weight: 300;
  line-height: 22px;
  font-size: 14px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: ${LOGO_SIZE}px;
  justify-content: space-between;
  padding-right: 24px;
`;

const LogoWrapper = styled.div`
  border: solid 1px var(--DIM_LIGHTER_EVEN);
  float: right;
  overflow: hidden;

  width: 100%;
  height: ${LOGO_SIZE}px;
  min-width: ${LOGO_SIZE}px;
  max-width: ${LOGO_SIZE * 1.5}px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 800px) {
    width: ${LOGO_SIZE}px;
  }
`;

const Line = styled.div`
  background: var(--DIM_LIGHTER_EVEN);
  height: 1px;
  clear: both;

  ::after {
    content: '';
    display: table;
    clear: both;
  }
`;

const LineBetween = styled(Line)`
  margin: 8px 0 8px 0;
  display: none;
`;

const LineBottom = styled(Line)`
  margin-bottom: 24px;
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: bold;
  color: var(--DIM_DARKER);
  line-height: 40px;
  float: left;
  word-break: break-all;
`;

const Type = styled.div`
  float: right;
  margin-top: 20px;
  color: var(--DIM_LIGHTER_EVEN);
  text-transform: uppercase;
  letter-spacing: 1.15px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const PluginWrapper = styled.div`
  cursor: pointer;
  border-radius: 8px;
  background: var(--WHITE);
  padding: 48px 24px 24px 24px;
  box-shadow: var(--SHADOW_DEFAULT);
  ::after {
    content: '';
    display: table;
    clear: both;
  }

  :hover {
    box-shadow: var(--SHADOW_HOVER);
  }

  @media (max-width: 1100px) {
    ${Details} {
      display: none;
    }
  }

  @media (max-width: 860px) {
    min-width: 300px;
    margin: 0 auto 24px auto;

    ${LogoWrapper} {
      float: none;
      margin: 24px auto 8px auto;
    }

    ${LineBetween} {
      display: block;
    }

    ${LineBottom} {
      display: none;
    }

    ${Title} {
      float: none;
    }

    ${Type} {
      float: none;
      margin: 0;
    }

    ${Content} {
      display: block;
      height: initial;

      ${Description} {
        margin-bottom: 16px;
      }
    }
  }

  @media (max-width: 420px) {
    border-radius: 0;
    border-left: none;
    border-right: none;
  }
`;

const ManagedInCloudWrapper = styled.div`
  @media (max-width: 420px) {
    margin: 0 auto;
    max-width: 200px;
  }
`;
