// @flow
// (Copyright) Confluent, Inc.

import { getDownloadUrl, type LicenseType, type Plugin } from '@confluent/connector-plugin-view';

import { PLUGIN_NAME_TO_CLOUD_PLUGIN_METADATA_MAP } from '../constants/plugin';
import URLS from '../constants/urls';
import { type PluginCloudMetadata } from '../types/plugin';

export const getPluginCloudMetadata = (plugin: Plugin): ?PluginCloudMetadata => {
  const {
    cloud_documentation_urls: pluginCloudDocumentationUrls,
    cloud_plugin_ids: pluginCloudPluginIds,
  } = plugin;

  const {
    cloud_documentation_urls: mappedCloudPluginDocumentationUrls,
    cloud_plugin_ids: mappedCloudPluginIds,
    deprecation: mappedCloudDeprecation,
  } = PLUGIN_NAME_TO_CLOUD_PLUGIN_METADATA_MAP[plugin.name] ?? {};

  // Attempt to pull this data from the plugin itself, fallback to mapping if not present
  const documentationUrls = pluginCloudDocumentationUrls ?? mappedCloudPluginDocumentationUrls;
  const pluginIds = pluginCloudPluginIds ?? mappedCloudPluginIds;
  const deprecation = mappedCloudDeprecation;

  if (!documentationUrls || !pluginIds) {
    return null;
  }

  const deprecationObj = {};
  if (deprecation) {
    const documentationUrl = documentationUrls.source ?? documentationUrls.sink;
    const newRecommendationText = deprecation.newRecommendation
      ? `Confluent recommends migrating to the new <a href='${documentationUrl}'>${deprecation.newRecommendation}</a> before the deprecated connector reaches the end of life. `
      : '';
    deprecationObj.showOnlyDeprecationMessage = Boolean(deprecation?.showOnlyDeprecationMessage);
    deprecationObj.deprecationMessage = `<b>Note</b>: The fully managed ${plugin.title} is deprecated. This connector will reach the end of life on Jan 9th, 2026. ${newRecommendationText}For more information, refer <a target='_blank' href='${URLS.CONNECTOR_SUPPORT_POLICY}'>here</a>.`;
  }

  return {
    documentationUrls,
    pluginIds,
    deprecation: deprecationObj,
  };
};

export const getIsSelfHostUnavailable = (licenseType: LicenseType): boolean =>
  licenseType !== 'free';

export const getIsDownloadAvailable = (plugin: Plugin): boolean => Boolean(getDownloadUrl(plugin));

export const getIsPluginDeployable = (plugin: Plugin): boolean => {
  const pluginCloudMetadata = getPluginCloudMetadata(plugin);
  const isDownloadAvailable = getIsDownloadAvailable(plugin);

  // It is either fully managed or deployable using self-hosted/custom connect/confluent platform
  return Boolean(pluginCloudMetadata) || isDownloadAvailable;
};
