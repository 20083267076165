// @flow
// (Copyright) Confluent, Inc.

export type LinkData = {
  text: string,
  url: string,
};

const URLS = {
  HOMEPAGE: 'https://www.confluent.io',
  HUB_HOME: 'https://www.confluent.io/hub',
  HUB_CLIENT: 'https://docs.confluent.io/current/confluent-hub/client.html',
  DOC_INSTALLING_PLUGINS:
    'https://docs.confluent.io/current/connect/userguide.html#installing-plugins',

  PRIVACY_POLICY: 'https://www.confluent.io/confluent-privacy-statement',
  MODERN_SLAVERY_POLICY: 'https://www.confluent.io/modern-slavery-policy',
  TERMS_OF_USE: 'https://www.confluent.io/confluent-hub-terms-of-use/',
  VERIFICATION_GUIDE:
    'https://cdn.confluent.io/wp-content/uploads/Verification-Guide-Confluent-Platform-Connectors-Integrations.pdf',
  SOFTWARE_LICENSE_AGREEMENT: 'https://www.confluent.io/software-evaluation-license/',

  DOC_DOWNLOAD_PLUGINS: 'https://not-sure-if-this-will-exist/',

  DOC_HOME: 'https://docs.confluent.io/current/',
  DOWLOAD: 'https://www.confluent.io/download/',

  DESCRIPTION_VERIFICATION_STANDARD: 'https://not-sure-if-this-will-exist/',
  DESCRIPTION_VERIFICATION_GOLD: 'https://not-sure-if-this-will-exist/',

  CONFLUENT_CLOUD_SIGNUP: 'https://confluent.cloud/signup',

  // Connector
  CONNECTOR_SUPPORT_POLICY:
    'https://docs.confluent.io/cloud/current/connectors/index.html#support-policy-for-ccloud-connectors',

  // Product
  PRODUCT: 'https://www.confluent.io/product/confluent-platform',
  CONFLUENT_PLATFORM: 'https://www.confluent.io/product/confluent-platform',
  KSQL: 'https://www.confluent.io/product/ksql',
  SUBSCRIPTION: 'https://www.confluent.io/subscription',
  PROFESSIONAL_SERVICES: 'https://www.confluent.io/services',
  TRAINING: 'https://www.confluent.io/training',
  CUSTOMERS: 'https://www.confluent.io/customers',

  // Cloud
  CLOUD: 'https://www.confluent.io/confluent-cloud',
  CONFLUENT_CLOUD: 'https://www.confluent.io/confluent-cloud',
  SUPPORT: 'https://www.confluent.io/confluent-cloud/support',
  SIGNUP: 'https://www.confluent.io/confluent-cloud/#sign-up',
  LOGIN: 'https://confluent.cloud/login',
  CLOUD_FAQS: 'https://www.confluent.io/confluent-cloud-faqs',

  // Solutions
  SOLUTIONS: 'https://www.confluent.io/solutions/microservices',
  INDUSTRY_SOLUTIONS: 'https://www.confluent.io/industry-solutions',
  MICOSERVICES: 'https://www.confluent.io/solutions/microservices',
  INTERNET_OF_THINGS: 'https://www.confluent.io/solutions/internet-of-things-iot',
  FINANCIAL_SERVICES: 'https://www.confluent.io/solutions/financial-services',
  FRAUD_DETECTION: 'https://www.confluent.io/fraud-detection',
  CUSTOMER_360: 'https://www.confluent.io/customer-360',
  AZURE_HYBRID_STREAMING: 'https://www.confluent.io/partner/azure-stack',

  // Developers
  DEVELOPERS: 'https://www.confluent.io/#',
  WHAT_IS_KAFKA: 'https://www.confluent.io/what-is-apache-kafka',
  RESOURCES: 'https://www.confluent.io/resources',
  EVENTS: 'https://events.confluent.io/',
  ONLINE_TALKS: 'https://www.confluent.io/online-talks',
  COMMUNITY: 'https://www.confluent.io/community',
  KAFKA_SUMMIT: 'https://kafka-summit.org/',
  KAFKA_TUTORIALS: 'https://developer.confluent.io/tutorials/',
  DOCS: 'https://docs.confluent.io/current/?_ga=2.73119955.1919663190.1571662482-1478261842.1568711775"',
  BLOG: 'https://www.confluent.io/blog/',
  QUICK_STARTS: 'https://developer.confluent.io/quickstart/kafka-on-confluent-cloud/',
  COURSES: 'https://developer.confluent.io/courses/#fundamentals',
  LANGUAGE_GUIDES: 'https://developer.confluent.io/get-started/java',
  DEMO: 'https://developer.confluent.io/demos-examples',
  NEWSLETTERS: 'https://developer.confluent.io/newsletter/',

  // About
  ABOUT: 'https://www.confluent.io/about',
  COMPANY: 'https://www.confluent.io/about',
  CAREERS: 'https://www.confluent.io/careers',
  PARTNERS: 'https://www.confluent.io/partners',
  NEWS: 'https://www.confluent.io/in-the-news',
  CONTACT: 'https://www.confluent.io/contact',

  // Social
  TWITTER: 'https://twitter.com/ConfluentInc',
  LINKEDIN: 'https://www.linkedin.com/company/confluent',
  GITHUB: 'https://github.com/confluentinc',
  SLIDESHARE: 'https://www.slideshare.net/ConfluentInc',
  YOUTUBE: 'https://www.youtube.com/confluent',
  INSTAGRAM: 'https://www.instagram.com/confluent_inc/',
  FACEBOOK: 'https://www.facebook.com/confluentinc/',
};

export const productLinks: LinkData[] = [
  {
    text: 'Confluent Platform',
    url: URLS.CONFLUENT_PLATFORM,
  },
  {
    text: 'KSQL',
    url: URLS.KSQL,
  },
  {
    text: 'Subscription',
    url: URLS.SUBSCRIPTION,
  },
  {
    text: 'Professional Services',
    url: URLS.PROFESSIONAL_SERVICES,
  },
  {
    text: 'Training',
    url: URLS.TRAINING,
  },
  {
    text: 'Customers',
    url: URLS.CUSTOMERS,
  },
];

export const cloudLinks: LinkData[] = [
  {
    text: 'Confluent Cloud',
    url: URLS.CONFLUENT_CLOUD,
  },
  {
    text: 'Support',
    url: URLS.SUPPORT,
  },
  {
    text: 'Sign Up',
    url: URLS.SIGNUP,
  },
  {
    text: 'Log In',
    url: URLS.LOGIN,
  },
  {
    text: 'Cloud FAQ',
    url: URLS.CLOUD_FAQS,
  },
];

export const developerLinks: LinkData[] = [
  {
    text: 'Quick Start',
    url: URLS.QUICK_STARTS,
  },
  {
    text: 'Courses',
    url: URLS.COURSES,
  },
  {
    text: 'Tutorials',
    url: URLS.KAFKA_TUTORIALS,
  },
  {
    text: 'Language Guides',
    url: URLS.LANGUAGE_GUIDES,
  },
  {
    text: 'Demo',
    url: URLS.DEMO,
  },
  {
    text: 'Community',
    url: URLS.COMMUNITY,
  },
  {
    text: 'Newsletter',
    url: URLS.NEWSLETTERS,
  },
];

export const aboutLinks: LinkData[] = [
  {
    text: 'Company',
    url: URLS.COMPANY,
  },
  {
    text: 'Partners',
    url: URLS.PARTNERS,
  },
  {
    text: 'News',
    url: URLS.NEWS,
  },
  {
    text: 'Events',
    url: URLS.EVENTS,
  },
  {
    text: 'Careers',
    url: URLS.CAREERS,
  },
  {
    text: 'Contact',
    url: URLS.CONTACT,
  },
];

export default URLS;
