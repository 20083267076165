// @flow
// (Copyright) Confluent, Inc.

// NOTE: This is temporary until the plugin additional attributes is seeded with this information
// https://confluentinc.atlassian.net/browse/EXP-8963
export const PLUGIN_NAME_TO_CLOUD_PLUGIN_METADATA_MAP = {
  'kafka-connect-salesforce-bulk-api': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-salesforce-bulk-api-v2-source.html',
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-salesforce-bulk-api-v2-sink.html',
    },
    cloud_plugin_ids: {
      source: 'SalesforceBulkApiV2Source',
      sink: 'SalesforceBulkApiV2Sink',
    },
  },
  'kafka-connect-activemq': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-activemq-source.html',
    },
    cloud_plugin_ids: {
      source: 'ActiveMQSource',
    },
  },
  'kafka-connect-aws-cloudwatch-logs': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-amazon-cloudwatch-logs-source.html',
    },
    cloud_plugin_ids: {
      source: 'CloudWatchLogsSource',
    },
  },
  'kafka-connect-aws-cloudwatch-metrics': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-amazon-cloudwatch-metrics-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'CloudWatchMetricsSink',
    },
  },
  'kafka-connect-aws-dynamodb': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-amazon-dynamo-db-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'DynamoDbSink',
    },
  },
  'kafka-connect-aws-lambda': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-aws-lambda-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'LambdaSink',
    },
  },
  'kafka-connect-aws-redshift': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-amazon-redshift-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'RedshiftSink',
    },
  },
  'kafka-connect-azure-blob-storage': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-blob-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'AzureBlobSink',
    },
  },
  'kafka-connect-azure-blob-storage-source': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-blob-source.html',
    },
    cloud_plugin_ids: {
      source: 'AzureBlobSource',
    },
  },
  'kafka-connect-azure-data-lake-gen2-storage': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-datalakeGen2-storage-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'AzureDataLakeGen2Sink',
    },
  },
  'kafka-connect-azure-event-hubs': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-event-hubs-source.html',
    },
    cloud_plugin_ids: {
      source: 'AzureEventHubsSource',
    },
  },
  'kafka-connect-azure-functions': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-functions-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'AzureFunctionsSink',
    },
  },
  'kafka-connect-azure-search': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-cognitive-search-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'AzureCognitiveSearchSink',
    },
  },
  'kafka-connect-azure-service-bus': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-service-bus-source.html',
    },
    cloud_plugin_ids: {
      source: 'AzureServiceBusSource',
    },
  },
  'kafka-connect-azure-sql-dw': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-synapse-analytics-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'AzureSqlDwSink',
    },
  },
  'kafka-connect-databricks-delta-lake': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-databricks-delta-lake-sink/databricks-aws-setup.html',
    },
    cloud_plugin_ids: {
      sink: 'DatabricksDeltaLakeSink',
    },
  },
  'kafka-connect-datadog-metrics': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-datadog-metrics-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'DatadogMetricsSink',
    },
  },
  'kafka-connect-datagen': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-datagen-source.html',
    },
    cloud_plugin_ids: {
      source: 'DatagenSource',
    },
  },
  'kafka-connect-elasticsearch': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-elasticsearch-service-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'ElasticsearchSink',
    },
  },
  'kafka-connect-gcp-bigtable': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-gcp-bigtable-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'BigTableSink',
    },
  },
  'kafka-connect-gcp-dataproc-sink': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-gcp-dataproc-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'DataprocSink',
    },
    deprecation: {
      newRecommendation: '',
      showOnlyDeprecationMessage: true,
    },
  },
  'kafka-connect-gcp-functions': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-google-cloud-functions-gen2-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'GoogleCloudFunctionsGen2Sink',
    },
    deprecation: {
      newRecommendation: 'Google Cloud Functions Gen 2 Sink Connector',
      showOnlyDeprecationMessage: true,
    },
  },
  'kafka-connect-gcp-pubsub': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-google-pubsub-source.html',
    },
    cloud_plugin_ids: {
      source: 'PubSubSource',
    },
  },
  'kafka-connect-gcp-spanner': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-gcp-spanner-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'SpannerSink',
    },
  },
  'kafka-connect-gcs': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-gcs-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'GcsSink',
    },
  },
  'kafka-connect-gcs-source': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-gcs-source.html',
    },
    cloud_plugin_ids: {
      source: 'GcsSource',
    },
  },
  'kafka-connect-github': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-github-source.html',
    },
    cloud_plugin_ids: {
      source: 'GithubSource',
    },
  },
  'kafka-connect-http': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-http-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'HttpSink',
    },
  },
  'kafka-connect-http-source': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-http-source.html',
    },
    cloud_plugin_ids: {
      source: 'HttpSource',
    },
  },
  'kafka-connect-ibmmq': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-ibmmq-source.html',
    },
    cloud_plugin_ids: {
      source: 'IbmMQSource',
    },
  },
  'kafka-connect-jdbc': {
    // Available fully managed, but in Confluent Cloud it is per database and not a single connector for all relational databases
    // EX: https://docs.confluent.io/cloud/current/connectors/cc-postgresql-source.html
  },
  'kafka-connect-kinesis': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-kinesis-source.html',
    },
    cloud_plugin_ids: {
      source: 'KinesisSource',
    },
  },
  'kafka-connect-mqtt': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-mqtt-sink.html',
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-mqtt-source.html',
    },
    cloud_plugin_ids: {
      sink: 'MqttSink',
      source: 'MqttSource',
    },
  },
  'kafka-connect-oracle-cdc': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-oracle-cdc-source/cc-oracle-cdc-source-features.html',
    },
    cloud_plugin_ids: {
      source: 'OracleCdcSource',
    },
  },
  'kafka-connect-pagerduty': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-http-sink-v2.html',
    },
    cloud_plugin_ids: {
      sink: 'HttpSinkV2',
    },
    deprecation: {
      newRecommendation: 'HTTP Sink V2 Connector',
      showOnlyDeprecationMessage: true,
    },
  },
  'kafka-connect-rabbitmq': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-rabbitmq-source.html',
    },
    cloud_plugin_ids: {
      source: 'RabbitMQSource',
    },
  },
  'kafka-connect-rabbitmq-sink': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-rabbitmq-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'RabbitMQSink',
    },
  },
  'kafka-connect-s3': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-s3-sink/cc-s3-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'S3_SINK',
    },
  },
  'kafka-connect-s3-source': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-s3-source.html',
    },
    cloud_plugin_ids: {
      source: 'S3Source',
    },
  },
  'kafka-connect-salesforce': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-salesforce-source-cdc.html',
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-salesforce-SObjects-sink.html',
    },
    cloud_plugin_ids: {
      source: 'SalesforceCdcSource',
      sink: 'SalesforceSObjectSink',
    },
  },
  'kafka-connect-servicenow': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/kafka-connectors/servicenow/current/source-connector',
      sink: 'https://docs.confluent.io/kafka-connectors/servicenow/current/sink-connector/overview.html',
    },
    cloud_plugin_ids: {
      source: 'ServiceNowSource',
      sink: 'ServiceNowSink',
    },
  },
  'kafka-connect-sftp': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/kafka-connectors/sftp/current/source-connector/index.html',
      sink: 'https://docs.confluent.io/kafka-connectors/sftp/current/sink-connector/index.html',
    },
    cloud_plugin_ids: {
      source: 'SftpSource',
      sink: 'SftpSink,',
    },
  },
  'kafka-connect-solace-sink': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-solace-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'SolaceSink',
    },
  },
  'kafka-connect-sqs': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-amazon-sqs-source.html',
    },
    cloud_plugin_ids: {
      source: 'SqsSource',
    },
  },
  'kafka-connect-zendesk': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-zendesk-source.html',
    },
    cloud_plugin_ids: {
      source: 'ZendeskSource',
    },
  },
  'debezium-connector-mysql': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-mysql-source-cdc-v2-debezium/cc-mysql-source-cdc-v2-debezium.html',
    },
    cloud_plugin_ids: {
      source: 'MySqlCdcSourceV2',
    },
    deprecation: {
      newRecommendation: 'Debezium MySQL CDC Source V2 Connector',
    },
  },
  'debezium-connector-postgresql': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-postgresql-cdc-source-v2-debezium/cc-postgresql-cdc-source-v2-debezium.html',
    },
    cloud_plugin_ids: {
      source: 'PostgresCdcSourceV2',
    },
    deprecation: {
      newRecommendation: 'Debezium PostgreSQL CDC Source V2 Connector',
    },
  },
  'debezium-connector-sqlserver': {
    cloud_documentation_urls: {
      source:
        'https://docs.confluent.io/cloud/current/connectors/cc-microsoft-sql-server-source-cdc-v2-debezium/cc-microsoft-sql-server-source-cdc-v2-debezium.html',
    },
    cloud_plugin_ids: {
      source: 'SqlServerCdcSourceV2',
    },
    deprecation: {
      newRecommendation: 'Debezium SQL Server CDC Source V2 Connector',
    },
  },
  'kafka-connect-redis': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-redis-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'RedisSink',
    },
  },
  'kafka-connect-cosmos': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-azure-cosmos-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'CosmosDbSink',
    },
  },
  'kafka-connect-mongodb': {
    cloud_documentation_urls: {
      source: 'https://docs.confluent.io/cloud/current/connectors/cc-mongo-db-source.html',
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-mongo-db-sink/cc-mongo-db-sink.html',
    },
    cloud_plugin_ids: {
      source: 'MongoDbAtlasSource',
      sink: 'MongoDbAtlasSink',
    },
  },
  'snowflake-kafka-connector': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-snowflake-sink/cc-snowflake-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'SnowflakeSink',
    },
  },
  'kafka-connect-bigquery': {
    cloud_documentation_urls: {
      sink: 'https://docs.confluent.io/cloud/current/connectors/cc-gcp-bigquery-storage-sink.html',
    },
    cloud_plugin_ids: {
      sink: 'BigQueryStorageSink',
    },
    deprecation: {
      newRecommendation: 'Google BigQuery Sink V2 Connector',
      showOnlyDeprecationMessage: true,
    },
  },
};
